<template>
  <div class="webview-container">
    <iframe  :src="websrc" frameborder="0" style="width:100%;height:100%;" allow="fullscreen" id="iframeWeb"></iframe>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      websrc: "",
    };
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide,
    }),
  },
  created() {
    this.websrc = this.$route.query.websrc;
    if(this.$route.query.isdownload && !this.is_web) {
      setTimeout(() => {
        this.closeCurrentPage();
      }, 50);
    }
  },
  methods: {
    closeCurrentPage() {
      if (navigator.userAgent.indexOf('MSIE') > 0) { // close IE
        if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
            window.opener = null;
            window.close();
        } else {
            window.open('', '_top');
            window.top.close();
        }

      } else { // close chrome;It is effective when it is only one.
        window.opener = null;
        window.open('', '_self');
        window.close();
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .webview-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .loading-img {
    width: 140px;
    height: 140px;
    object-fit: contain;
  }
</style>